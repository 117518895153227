<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="600" height="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" v-t="'uploadCsv'"></v-btn>
            </template>
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    <v-btn icon @click="closeModal" class="mr-10">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t('uploadCsvDoc') }}
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-file-input :label="$t('fileInput')" filled prepend-icon="mdi-upload" @change="readContent"></v-file-input>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" v-t="'code'"></th>
                                    <th class="text-left" v-t="'quantity'"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="el in csv.content" :key="el.itemCode">
                                    <td>{{ el.itemCode }}</td>
                                    <td>{{ el.quantity }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="confirm" v-t="'confirm'"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    import {call, get} from "vuex-pathify";
    export default {
        name: "UploadCsv",
        mounted() {
            this.initialize();
        },
        data: () => ({
            dialog: false,
            csv: {
                content : []
            },
        }),
        methods: {
            initialize : function() {
                this.initItemCollection();
            },
            readContent : function(data) {
                if (!data) return;
                var reader = new FileReader();
                reader.readAsText(data);
                reader.onload = () => {
                    this.csv.content.push(...reader.result
                        .split(/\r?\n|\r/)
                        .filter(el => !!el)
                        .map(el => ({ 
                            itemCode : el.split(";")[0].trim().replaceAll("'", ''),
                            quantity : +el.split(";")[1].match(/\d+/).flat()[0]
                        }))
                        .filter(el => !!el.quantity && !!(this.items.find(x => x.code === el.itemCode)))
                    );
                }
            },
            confirm: function() {
                this.$emit("loadedCsv", this.csv.content);
                this.closeModal();
            },
            closeModal: function() {
                this.csv.content.length = 0;
                this.dialog = false;
            },
            ...call("items", [
                "initItemCollection",
            ]),
        },
        computed: {
            items: get("items/items"),
        },
    }
</script>