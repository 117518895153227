<template>
<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <h1 style="width: 100%;">{{ $t('noData') }}</h1>
    <!-- <v-img :src="require('@/assets/dog_looking_for.svg')" style="height: 49vh;width: 22vw;/* margin-left: 6vw; */"/> -->
    <lottie-animation ref="anim" :animationData="require('@/assets/dog_looking_for.json')"/>
</div>
</template>
<script>
    import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
    export default {
        name: "NoDataImg",
        components: {
            LottieAnimation
        }
    }
</script>