<template>
  <div>
    <v-stepper v-model="currentStep" style="max-height: 90vh">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1" @click="move(1)">
          <!--  @click="move(1)" -->
          {{ $t('family') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2" @click="move(2)">
          <!--  @click="move(2)" -->
          {{ $t('document') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3" @click="move(3)">
          <!--  @click="move(3)" -->
          {{ $t('rows') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4" @click="move(4)">
          <!--  @click="move(4)" -->
          {{ $t('generalData') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 5" step="5" @click="move(5)">
          <!--  @click="move(5)" -->
          {{ $t('shipment') }}
        </v-stepper-step>
      </v-stepper-header>
      <!-- CHIPS -->
      <div style="display: flex; justify-content: center;">
        <v-chip @click="move(2)" outlined class="ma-2 pa-4" color="primary" text-color="primary"
          v-if="!!orderDraft.customerId">
          <v-avatar left><v-icon>mdi-account</v-icon></v-avatar>
          {{ $t('customer') }}: {{ getCustomerName(orderDraft.customerId) }}
        </v-chip>
        <v-chip @click="move(2)" outlined class="ma-2 pa-4" color="primary" text-color="primary"
          v-if="!!selectedCurrency">
          <v-avatar left><v-icon>{{ "mdi-currency-" + this.selectedCurrency.name?.toLowerCase() }}</v-icon></v-avatar>
          {{ $t('currency') }}: {{ this.selectedCurrency.name }}
        </v-chip>
        <v-chip outlined class="ma-2 pa-4" color="primary" text-color="primary" v-if="!!orderDraft.priceCatalogId"
          @click="move(2)">
          <v-avatar left><v-icon>mdi-clipboard-list-outline</v-icon></v-avatar>
          {{ $t('priceCatalog') }}: {{ getListName(orderDraft.priceCatalogId) }}
        </v-chip>
        <v-chip outlined @click="move(3)" class="ma-2 pa-4" :color="showTotalError ? 'error' : 'primary'"
          :text-color="showTotalError ? 'error' : 'primary'" v-if="!!+getFooter().rowsAmountsBeforeDiscount">
          <v-avatar left><v-icon>mdi-cash-multiple</v-icon></v-avatar>
          {{ $t('total') }}: {{ getFormattedCurrency(+orderDraft.documentCurTotal) }}
        </v-chip>
        <v-chip @click="move(4)" class="ma-2 pa-4" outlined color="primary" text-color="primary"
          v-if="!!orderDraft.paymentTermId">
          <v-avatar left><v-icon>mdi-credit-card-outline</v-icon></v-avatar>
          {{ $t('paymentTerm', {r:''}) }}: {{ getPaymentType(orderDraft.paymentTermId) }}
        </v-chip>
      </div>
      <div style="display: flex; justify-content: center;">
        <v-alert v-if="!!showTotalError" dense outlined type="error">
          {{ $t('errorNewOrder') }}
        </v-alert>
      </div>
      <v-stepper-items>
        <!-- SELECT AGENT 1 -->
        <v-stepper-content step="1" style="padding: 0">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form ref="form" v-model="step1Validation">
                <v-card>
                  <v-card-text class="modal-main-content-45">
                    <v-col cols="12" offset-sm="3" sm="6">
                      <v-autocomplete v-model="orderDraft.familyId" :items="familiesDropdown" :rules="selectionRules"
                        :label="$t('brand')" :disabled="isFamilySelectionDisabled" required></v-autocomplete>
                    </v-col>
                  </v-card-text>
                  <v-card-text align="center" style="height: 38px; position: relative">
                    <v-fab-transition>
                      <v-btn absolute color="primary" right fab top @click="validate" :disabled="!step1Validation">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- DOCUMENT STEPPER 2 -->
        <v-stepper-content step="2" style="padding: 0">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card>
                <v-form v-model="step2Validation" ref="form">
                  <v-card-text class="modal-main-content-45">
                    <v-row>
                      <v-col cols="12" sm="4" class="date-picker">
                        <label v-t="'date'"></label>
                        <DatePickerField :isEdit="true" :disabled="false" :value="orderDraft.orderDate"
                          :field="$t('orderDate')" :loading="false" @emitUpdate="orderDatePickerHandler"></DatePickerField>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-autocomplete v-model="orderDraft.orderTypeId" :items="orderTypesDropdown"
                          :label="$t('orderType')" required :rules="selectionRules"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-autocomplete v-model="orderDraft.priceCatalogId" :items="priceCatalogDropdown"
                          :label="$t('priceCatalog')" :rules="selectionRules" required disabled></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-autocomplete v-model="orderDraft.customerId" :items="customersDropdown"
                          :label="$t('customer')" :rules="selectionRules" @change="customerChanged"
                          :prepend-icon="getCustomerIcon()" required clearable></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-autocomplete v-model="orderDraft.customerLocationId" :items="customerLocationsDropdown"
                          :rules="selectionRules" :label="$t('customerLocation')"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field v-model="orderDraft.customerPurchaseOrder" :counter="50" :rules="poRules"
                          class="pr-2" hide-details="auto" :label="$t('po')"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-switch 
                          v-model="orderDraft.hasDifferentInvoicingCustomer" 
                          :label="$t('invoiceToDifferentCustomer')"
                          @change="changedHasDifferentInvoicingCustomer"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="!!orderDraft.hasDifferentInvoicingCustomer">
                        <v-autocomplete v-model="orderDraft.invoicingCustomerId" :items="customersDropdown"
                          :label="$t('invoicingCustomer', {r:''})" :rules="selectionRules"
                          @change="invoicingCustomerChanged"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="!!orderDraft.hasDifferentInvoicingCustomer">
                        <v-autocomplete v-model="orderDraft.invoicingCustomerLocationId"
                          :items="invoicingCustomerLocationsDropdown" :label="$t('invoicingCustomerLocation')"
                          :disabled="orderDraft.invoicingCustomerId == null"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-row class="align-center justify-center">
                    <v-col cols="12" sm="1" class="justify-start">
                      <v-btn color="primary" fab @click="movePrevious" style="margin-left: 12px;">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-textarea v-model="orderDraft.notes" :counter="255" :rules="noteRules" :label="$t('notes')"
                        prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-textarea v-model="orderDraft.privateNotes" :counter="255" :rules="noteRules"
                        :label="$t('internalNotes')" prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="1" class="justify-end" style="display: flex;">
                      <v-btn color="primary" fab right top :disabled="!step2Validation" @click="validate"
                        style="margin-right: 12px;">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- ORDER STEPPER 3 -->
        <v-stepper-content step="3" style="padding: 0">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card>
                <v-card-text v-if="!minOrderReached()">
                  <v-alert border="bottom" colored-border type="warning" elevation="7">
                    {{ $t('minNotReached', {param:getFormattedCurrency(getCustomer().minOrder)}) }}
                  </v-alert>
                </v-card-text>

                <v-card-text class="py-1" style="overflow-y: hidden !important;">
                  <div>
                    <div v-if="(!toggle_import || toggle_import == 'grid') && currentStep == 3 && !!orderDraftRowsAreLoaded">
                      <TelerikGrid 
                        class="modal-main-content-46" 
                        :collection="orderRows" 
                        :collapseKits="true" 
                        :orderId="'new'" 
                        @updatedCollection="saveRows" 
                        :familyId="orderDraft.familyId" 
                        :vatPercentageId="getCustomer()?.defaultVatRate"
                      ></TelerikGrid>
                    </div>
                    <div v-if="toggle_import == 'excel'">
                      <TelerikSpreadsheetV2 class="modal-main-content-65" @loadedDataOnSpreadSheet="handleNewCollection">
                      </TelerikSpreadsheetV2>
                    </div>
                    <v-dialog v-if="toggle_import == 'catalogue'" :value="true" fullscreen hide-overlay
                      transition="dialog-bottom-transition">
                      <Catalogue class="modal-main-content-65" :collection="orderRows" :familyId="familyId"
                        @exportedCart="handleCart" @closeCart="toggle('grid')"></Catalogue>
                    </v-dialog>
                  </div>
                </v-card-text>
                <v-card-text class="pt-4 pb-5 ma-0">
                  <v-row>
                    <v-col cols="12" sm="4"></v-col>
                    <v-col cols="12" sm="4" class="justify-center pa-0 ma-0" style="display: flex;">
                      <v-btn-toggle v-model="editSelector" rounded>
                        <v-btn value="left" @click="toggle('grid')">
                          <v-icon left>mdi-grid</v-icon>
                          <span class="hidden-sm-and-down" v-t="'grid'"></span>
                        </v-btn>
                        <v-btn value="center" @click="toggle('excel')">
                          <v-icon left>mdi-file-excel</v-icon>
                          <span class="hidden-sm-and-down" v-t="'excel'"></span>
                        </v-btn>
                        <v-btn value="right" @click="toggle('catalogue')">
                          <v-icon left>mdi-cart</v-icon>
                          <span class="hidden-sm-and-down" v-t="'cart'"></span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-0 ma-0 align-center" style="display: flex;">
                      <div v-if="toggle_import === 'grid'">
                        <v-chip class="mr-2">
                          {{ $t('quantity') }}:
                          {{ +getFooter().totalQuantity }}
                        </v-chip>
                        <v-chip class="mr-2">
                          {{ $t('beforeDiscount') }}:
                          {{ getFormattedCurrency(+getFooter().rowsAmountsBeforeDiscount) }}
                        </v-chip>
                        <v-chip class="mr-2">
                          {{ +getFooter().rowsDiscountAmount > 0 ? $t('surcharge') : $t('discount') }}
                          {{ getFormattedCurrency(+getFooter().rowsDiscountAmount) }}
                        </v-chip>
                        <v-chip class="mr-2">
                          {{ $t('total') }}:
                          {{ getFormattedCurrency(+getFooter().documentCurRowsTotal) }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-row class="align-center justify-center" v-if="toggle_import === 'grid'">
                  <v-col cols="12" sm="1" class="justify-start">
                    <v-btn color="primary" fab @click="movePrevious" style="margin-left: 12px;">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-textarea v-model="orderDraft.notes" :counter="255" :rules="noteRules" :label="$t('notes')"
                      prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-textarea v-model="orderDraft.privateNotes" :counter="255" :rules="noteRules" :label="$t('internalNotes')"
                      prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="1" class="justify-end" style="display: flex;">
                    <v-btn color="primary" fab right top @click="moveNext" style="margin-right: 12px;">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- GENERAL DATA STEPPER 4 -->
        <v-stepper-content step="4" style="padding: 0">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card class="mb-4">
                <v-card-text style="height: 55vh; overflow-y: scroll;">
                  <v-row>
                    <v-col class="mt-0 pt-0 mb-2" cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label class="font-weight-bold" v-t="'summary'"></label>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="10">
                          <v-autocomplete v-model="orderDraft.oldVatPercentageId" :items="vatPercentagesDropdown"
                            :label="$t('vat')" disabled @change="saveOrderDraft"></v-autocomplete>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="2" v-if="!!geographicalSalesTaxes">
                          <v-switch v-model="taxEligible" :label="$t('taxEligible')"
                            @change="changedTaxEligible()"></v-switch>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="5">
                          <v-text-field v-show="discountType === 'percentage'"
                            v-model="orderDraft.orderDiscountPercentage" hide-details="auto" :label="$t('surDisPerc')"
                            type="number" @change="applyDiscount"></v-text-field>
                          <v-text-field v-show="discountType === 'currency'"
                            v-model="orderDraft.documentCurOrderDiscountAbsolute" hide-details="auto"
                            :label="$t('surDis')" type="number" @change="applyDiscount"></v-text-field>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="2">
                          <v-btn-toggle v-model="discountType" class="mt-5" mandatory>
                            <v-btn small value="currency"><v-icon>mdi-currency-eur</v-icon></v-btn>
                            <v-btn small value="percentage"><v-icon>mdi-percent</v-icon></v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col class="mt-0 pt-0 mb-2" cols="12" sm="5">
                          <v-text-field v-model="orderDraft.documentCurDeliveryCharges" hide-details="auto"
                            :label="$t('deliveryCharges')" min="0" type="number" @change="saveOrderDraft"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="mt-0 pt-0 mb-2" cols="12" sm="6">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label class="font-weight-bold" v-t="'paymentInformations'"></label>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="8">
                          <v-autocomplete v-model="orderDraft.paymentTermId" :items="paymentTermsDropdown" :label="$t('payment')"
                            @change="selectedPaymentTerm"></v-autocomplete>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="4">
                          <v-text-field v-model="orderDraft.documentCurCollectionCharges" hide-details="auto"
                            :label="$t('collectionCharges')" min="0" type="number" @change="saveOrderDraft"></v-text-field>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="12" v-if="!!showPaymentMethods">
                          <v-autocomplete v-model="orderDraft.paymentMethodId" :items="paymentMethodsDropdown"
                            :label="$t('paymentMethod', {r:''})" @change="selectedPaymentMethod"></v-autocomplete>
                        </v-col>
                        <v-col class="mt-0 pt-0" cols="12" sm="12" v-if="!!showIbanSelector">
                          <v-autocomplete v-model="orderDraft.customerIbanId" :items="customerIbanDropdown"
                            :label="$t('iban', {r:''})"></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col class="mt-0 pt-0 date-picker" cols="12" sm="9">
                      <label v-t="'estimatedDeliveryDate'"></label>
                      <DatePickerField :isEdit="true" :disabled="false" :value="orderDraft.estimatedDeliveryDate"
                        :field="'estimatedDeliveryDate'" :loading="false"
                        @emitUpdate="estimatedDeliveryDatePickerHandler"></DatePickerField>
                    </v-col>
                    <v-col class="mt-0 pt-0" cols="12" sm="3">
                      <v-checkbox hide-details="auto" :label="$t('mandatoryShippingDate')"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row class="mx-1 mt-5">
                    <v-col cols="12" sm="7">
                      <v-row class="castelletto-top castelletto-left">
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                          <span class="mini-label-castelletto" v-t="'discP'"></span><br />
                          <p class="float-right">{{ $numerals(orderDraft.orderDiscountPercentage / 100, '0.00 %') }}</p>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                          <span class="mini-label-castelletto" v-t="'discountAmount'"></span><br />
                          <p class="float-right">{{ orderDraft.documentCurOrderDiscountAbsolute | tenantCurrency }}</p>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                          <span class="mini-label-castelletto" v-t="'deliveryCharges'"></span><br />
                          <p class="float-right">{{ orderDraft.documentCurDeliveryCharges | tenantCurrency }}</p>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                          <span class="mini-label-castelletto" v-t="'collectionExpenses'"></span><br />
                          <p class="float-right">{{ orderDraft.documentCurCollectionCharges | tenantCurrency }}</p>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="2">
                          <span class="mini-label-castelletto" v-t="'stampDuty'"></span><br />
                          <p class="float-right">{{ 0 | tenantCurrency }}</p>
                        </v-col>
                      </v-row>
                      <v-row class="castelletto-bottom castelletto-left">
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="4">
                          <span class="mini-label-castelletto" v-t="'vatCode'"></span><br />
                          <div style="display: flex; flex-direction: row; justify-content: space-between;"
                            v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                            <span>{{ vatPercentageById(el.vatPercentageId)?.description ?? $t('noDescri') }}</span>
                            <span>{{ vatPercentageById(el.vatPercentageId)?.percentage ?? 0 }} %</span>
                          </div>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="5">
                          <span class="mini-label-castelletto" v-t="'vatAssessment'"></span><br />
                          <div style="display: flex; flex-direction: row; justify-content: space-between;"
                            v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                            <span>{{ el.qty }} {{ `Item${el.qty > 1 ? `s` : ``}` }}</span>
                            <span>{{ el.documentCurRowTaxable | tenantCurrency }}</span>
                          </div>
                        </v-col>
                        <v-col class="castelletto mx-0 my-0" cols="12" sm="3">
                          <span class="mini-label-castelletto" v-t="'tax'"></span><br />
                          <div style="display: flex; flex-direction: row; justify-content: space-between;"
                            v-for="el in getCastellettoVATInfo()" v-bind:key="el.vatPercentageId">
                            <span>&nbsp;</span>
                            <span>{{ el.documentCurRowTaxes | tenantCurrency }}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="castelletto castelletto-top castelletto-bottom cstelletto-left mt-0 mb-0" cols="12"
                      sm="1">
                      <span class="mini-label-castelletto" v-t="'currency'"></span>
                      <p>{{ selectedCurrency.symbol }}</p>
                    </v-col>
                    <v-col class="castelletto castelletto-top castelletto-bottom castelletto-right mt-0 mb-0" cols="12"
                      sm="4">
                      <v-row>
                        <v-col cols="12" sm="12" class="align-right">
                          <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                </template>
                                <span v-t="'rowsTotalAfterDiscSurc'"></span>
                              </v-tooltip>
                              {{ $t('subTotal') }}:
                            </span>
                            <span>{{ orderDraft.documentCurSubtotal | tenantCurrency }}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="align-right">
                          <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                </template>
                                <span v-t="'subtotalDeliveryCollection'"></span>
                              </v-tooltip>
                              {{ $t('taxable') }}:
                            </span>
                            <span>{{ orderDraft.documentCurTaxable | tenantCurrency }}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="align-right">
                          <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                </template>
                                <span v-t="'taxes'"></span>
                              </v-tooltip>
                              {{ $t('taxes') }}:
                            </span>
                            <span class="mr-2">{{ orderDraft.documentCurTaxes | tenantCurrency }}</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="align-right">
                          <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon x-small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                </template>
                                <span>{{ $t('taxable') }} + {{ $t('taxes') }}</span>
                              </v-tooltip>
                              {{ $t('total') }}:
                            </span>
                            <span class="mr-2">{{ orderDraft.documentCurTotal | tenantCurrency }}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row class="align-center justify-center">
                <v-col cols="12" sm="1" class="justify-start">
                  <v-btn color="primary" fab @click="movePrevious" style="margin-left: 12px;">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-textarea v-model="orderDraft.notes" :counter="255" :rules="noteRules" :label="$t('notes')"
                    prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-textarea v-model="orderDraft.privateNotes" :counter="255" :rules="noteRules" :label="$t('internalNotes')"
                    prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" class="justify-end" style="display: flex;">
                  <v-btn color="primary" fab right top @click="moveNext" style="margin-right: 12px;">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- SHIPMENT 5  -->
        <v-stepper-content step="5" style="padding: 0">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card>
                <v-card-text class="modal-main-content-45">
                  <v-row>
                    <v-col cols="12" offset-sm="3" sm="6">
                      <v-radio-group v-model="shipmentSelectionType" mandatory row @change="changedShipmentSelectionType">
                        <v-radio :label="$t('orderCustomer')" value="same"></v-radio>
                        <v-radio :label="$t('childrenCustomer')" value="childrens"></v-radio>
                        <v-radio :label="$t('allCustomers')" value="all"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2" v-if="shipmentSelectionType === 'same'">
                    <v-col cols="12" offset-sm="3" sm="5">
                      <v-autocomplete v-model="orderDraft.shippingCustomerLocationId" :items="customerLocationsDropdown" :label="$t('shipTo')" @change="doSomeKindOfMagic()">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-dialog v-model="dialog" width="800">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab color="primary" outlined dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">{{ $t('newParam', {param: $t('location')}) }}</v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12" class="mb-3"></v-col>
                              <v-col cols="12" sm="4">
                                <div>
                                  <label>{{ $t('country', {r:''}) }}</label>
                                  <div class="inf-font">
                                    <AutoComplete :value="selectedLocation.geonameCountryCode"
                                      :items="dropdownCountries" :field="'geonameCountryCode'"
                                      @emitUpdate="handleLocation" :isEdit="true"></AutoComplete>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="10" md="3" v-if="mappedPostalCodes.length > 0">
                                <div>
                                  <label>{{ $t('postalCodes')}} {{ mappedPostalCodes.length }}</label>
                                  <div class="inf-font">
                                    <AutoComplete @emitUpdate="handleLocation" class="address-text" :isEdit="true"
                                      clearable :disabled="!selectedLocation.geonameCountryCode"
                                      :value="selectedLocation.postalCode" :loading="isLoadingPostalCodes"
                                      autocomplete="nope" :field="'postalCode'" :items="mappedPostalCodes"
                                      :placeholder="$t('postalCode')"></AutoComplete>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="2" md="1" v-if="mappedPostalCodes.length > 0">
                                <v-icon @click="clearPostalCodes()">mdi-close</v-icon>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" v-else>
                                <div>
                                  <label v-t="'postalCode'"></label>
                                  <div class="inf-font">
                                    <TextField @emitUpdate="handleLocation" class="address-text" clearable
                                      :isEdit="true" :disabled="!selectedLocation.geonameCountryCode"
                                      :value="selectedLocation.postalCode" autocomplete="nope" :loading="false"
                                      :field="'postalCode'" :placeholder="$t('postalCode')"></TextField>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <div>
                                  <label v-t="'city'"></label>
                                  <div class="inf-font">
                                    <TextField :value="selectedLocation.city" @emitUpdate="handleLocation"
                                      :field="'city'" :isEdit="true"></TextField>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <div>
                                  <label style="text-transform: capitalize">{{ $t(geonameAdmin1Description) }}</label>
                                  <div class="inf-font">
                                    <AutoComplete :value="selectedLocation.geonameRegionCode" :items="dropdownRegions"
                                      :field="'geonameRegionCode'" @emitUpdate="handleLocation" :isEdit="true">
                                    </AutoComplete>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <div>
                                  <label style="text-transform: capitalize">{{ $t(geonameAdmin2Description) }}</label>
                                  <div class="inf-font">
                                    <AutoComplete :value="selectedLocation.geonameProvinceCode"
                                      :items="dropdownProvinces" :field="'geonameProvinceCode'"
                                      @emitUpdate="handleLocation" :isEdit="true"></AutoComplete>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <div>
                                  <label v-t="'address'"></label>
                                  <div class="inf-font">
                                    <TextField :value="selectedLocation.address" @emitUpdate="handleLocation"
                                      :field="'address'" :isEdit="true"></TextField>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <div>
                                  <label v-t="'contactName'"></label>
                                  <div class="inf-font">
                                    <TextField :value="selectedLocation.name" @emitUpdate="handleLocation"
                                      :field="'name'" :isEdit="true"></TextField>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="dialog = false" v-t="'close'"></v-btn>
                            <v-btn color="primary" text @click="saveLocation" v-t="'confirm'"></v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" offset-sm="3" sm="6" v-if="shipmentSelectionType !== 'same'">
                      <v-autocomplete v-model="orderDraft.shippingCustomerId" :items="shippingCustomersDropdown"
                        :label="$t('shippingCustomer')" :rules="selectionRules"
                        @change="shippingCustomerChanged"></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" offset-sm="3" sm="6" v-if="shipmentSelectionType !== 'same'">
                      <v-autocomplete v-model="orderDraft.shippingCustomerLocationId"
                        :items="shippingCustomerLocationsDropdown" :label="$t('shippingCustomerLocation')"></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" offset-sm="3" sm="6">
                      <v-autocomplete :items="packageTypes" :label="$t('packagingType')" v-model="orderDraft.shipmentType"
                        @change="shipmentTypeChanged" required :rules="selectionRules"></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="showPalletHeight">
                    <v-col cols="12" offset-sm="3" sm="6">
                      <v-text-field :label="$t('palletHeightCm')" v-model="orderDraft.palletHeight" min="0"
                        type="number"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-row class="align-center justify-center">
                  <v-col cols="12" sm="1" class="justify-start">
                    <v-btn color="primary" fab @click="movePrevious" style="margin-left: 12px;">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-textarea v-model="orderDraft.notes" :counter="255" :rules="noteRules" :label="$t('notes')"
                      prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-textarea v-model="orderDraft.privateNotes" :counter="255" :rules="noteRules" :label="$t('internalNotes')"
                      prepend-icon="mdi-comment" rows="5" solo></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="1" class="justify-end" style="display: flex;">
                    <v-btn color="primary" fab right top @click="moveNext" style="margin-right: 12px;">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>


<script>
import TelerikSpreadsheetV2 from "@/components/TelerikSpreadSheetV2";
import TelerikGrid from "@/components/TelerikGrid";
import DatePickerField from '@/components/utility/DatePickerField';
// import UploadUbl from '@/components/UploadUbl';
import CheckBoxField from "@/components/utility/CheckBoxField";
import { call, get, sync } from "vuex-pathify";

export default {
  props: ["familyId"],
  name: "NewOrder",
  emits: ["click"],
  data: () => ({
    isLoadingPostalCodes: false,
    selectedLocation: {
      geonameCountryCode: null,
      postalCode: '',
      geonameProvinceCode: null,
      geonameRegionCode : null,
      geonameAdmin1Description: null,
      geonameAdmin2Description: null,
    },
    mappedPostalCodes: [],
    defaultOrderDate: new Date(),
    step1Validation: false,
    step2Validation: false,
    step3Validation: false,
    step4Validation: false,
    step5Validation: false,
    dialog: false,
    toggle_import: 'grid',
    validStepOne: false,
    shipmentSelectionType: 'same',
    discountType: 'currency',
    discountValue: 0,
    orderDraftRowsAreLoaded: false,
    editSelector: null,
    customerIbanDropdown: [],
    selectedCustomer: {},
    validOrder: false,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    docDatePicker: false,
    customerLocationsDropdown: [],
    shippingCustomersDropdown: [],
    invoicingCustomerLocationsDropdown: [],
    shippingCustomerLocationsDropdown: [],
    selectedCurrency: {},
    taxEligible: false,
    showPalletHeight: false,
    originalPostalCodes: [],
    currentStep: 1,
  }),
  components: {
    TelerikSpreadsheetV2,
    TelerikGrid,
    DatePickerField,
    CheckBoxField
    // UploadUbl,
  },
  created: function () {
    this.initialize();
    this.defaultOrderDate = this.$moment().utc();
  },
  methods: {
    initialize: function () {
      this.apiOrderDraftGet();
      this.initItemCollection();
      this.initOrderDraftCollection();
      this.initAgentCollection()
        .then(() => {
          if (!this.orderDraft.agentId && this.activeAgentsDropdown.length == 1) this.orderDraft.agentId = this.activeAgentsDropdown[0].value;
          this.agentChanged();
        });
      this.initFamilyCollection();
      this.initPriceCatalogCollection();
      this.initCustomerCollection();
      this.orderDraftRowsGet();
      this.initOrderTypesCollection();
      this.initPaymentMethodCollection();
      this.initCountryCollection();
    },
    doSomeKindOfMagic: function(){
      console.log(this.orderDraft.hasDifferentShippingCustomer);
      console.log(this.orderDraft.shippingCustomerId);
      console.log(this.orderDraft.customerLocationId , '===', this.orderDraft.shippingCustomerLocationId);
      if(this.orderDraft.shippingCustomerLocationId !== this.orderDraft.customerLocationId){
        this.orderDraft.hasDifferentShippingCustomer = true;
        this.orderDraft.shippingCustomerId = this.orderDraft.customerId;
      }
    },
    saveLocation: function () {
      this.selectedLocation.country = this.countries?.find((el) => el.iso == this.selectedLocation.geonameCountryCode)?.country ||
        this.selectedLocation.country;
      if (typeof this.selectedLocation.postalCode == "number") {
        let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
        this.selectedLocation.city = postalCode.placeName;
        this.selectedLocation.geometry = `{ "type" : 'Point', "coordinates" : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
        this.selectedLocation.geonameRegionCode =
          this.selectedLocation.geonameRegionCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
        this.selectedLocation.geonameProvinceCode =
          this.selectedLocation.geonameProvinceCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
        this.selectedLocation.postalCode = postalCode.postalCode;
      }
      this.selectedLocation.locationNumber = 0;
      this.selectedLocation.active = true;
      this.selectedLocation.default = false;
      this.selectedLocation.visibility = true;
      this.customerLocationsPost({ ...this.selectedLocation, customerId: +(this.getCustomer()?.id) })
      .then(() => this.apiCustomersIdLocationsGet(this.selectedCustomer.id))
      .then((res) => {
        res = res.sort((a, b) => b.id - a.id);
        this.orderDraft.hasDifferentShippingCustomer = true;
        this.orderDraft.shippingCustomerId = this.selectedCustomer.id;
        this.orderDraft.shippingCustomerLocationId = res[0]?.id;
        this.customerLocationsDropdown = res.map(item => ({ text: `${item.name? `${item.name} - ` : ``}${item.address}`, value: item.id }));
        this.dialog = !this.dialog;
      });
    },
    handleLocation: function (obj) {
      this.selectedLocation[obj.field] = obj.value;
      switch (obj.field) {
        case "geonameCountryCode":
          if (!!this.selectedLocation.geonameCountryCode) {
            this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
            if (!!this.selectedLocation.geonameRegionCode)
              this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          }
          break;
        case "geonameRegionCode":
          if (!!this.selectedLocation.geonameRegionCode)
            this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          break;
        case "postalCode":
          if (!obj.value || obj.value.length < 4) this.mappedPostalCodes.length = 0;
          else if (!!obj.value && obj.value.length >= 4) {
            this.isLoadingPostalCodes = true;
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
              this.apiGeonamePostalCodeGet({
                countryIsoCode: this.selectedLocation.geonameCountryCode,
                postalCode: obj.value,
              }).then((response) => {
                this.isLoadingPostalCodes = false;
                this.selectedLocation.postalCode = response[0]?.id || this.selectedLocation.postalCode;
                this.originalPostalCodes.push(...response);

                if (typeof this.selectedLocation.postalCode == "number") {
                  let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
                  this.selectedLocation.geometry = `{ type : 'Point', coordinates : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
                  this.selectedLocation.geonameRegionCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
                  this.selectedLocation.geonameProvinceCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
                }

                if (!!this.selectedLocation.geonameCountryCode)
                  this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
                if (!!this.selectedLocation.geonameRegionCode)
                  this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);

                let mappedResponse = response.map((el) => ({
                  text: `${el.postalCode} - ${el.placeName}`,
                  value: el.id,
                }));
                this.mappedPostalCodes.push(...mappedResponse);
                this.selectedLocation.city = this.originalPostalCodes.find(
                  (el) => el.id == this.selectedLocation.postalCode
                )?.placeName;
              });
            }, 2000);
          }

          break;
        // default:
      }
    },
    clearPostalCodes: function () {
      this.mappedPostalCodes.length = 0;
      this.selectedLocation.postalCode = null;
      this.selectedLocation = { geonameCountryCode: this.selectedLocation.geonameCountryCode, active: true, default: true, visibility: true };
    },
    handleCart: function (data) {
      this.orderRows.length = 0;
      data.forEach((el, i) => {
        el.itemCode = (el.itemCode || el.code).toUpperCase();
        const item = (this.items.find(item => item.code == el.itemCode) || {});
        const lastRowNumber = (this.orderRows[this.orderRows.length - 1] || {}).rowNumber || 0;
        const nextRowNumber = ((lastRowNumber + 10) / 10).toFixed(0) * 10;

        el.itemDescription = item.description || "";
        el.documentCurRowOriginalPrice = (this.itemPrices.find(itemPrice => itemPrice.itemId === item.id) || {}).price || 0.01;
        el.rowDiscountPercentage = 0;
        el.rowNumber = nextRowNumber;

        if (!!el.itemCode && !!el.quantity && !!el.documentCurRowOriginalPrice) {
          el.documentCurRowAmountBeforeDiscount = (+el.quantity * +el.documentCurRowOriginalPrice).toFixed(2);
          el.documentCurRowDiscountAmount = +el.rowDiscountPercentage === 0 ? 0 : (+el.documentCurRowAmountBeforeDiscount * (el.rowDiscountPercentage / 100)).toFixed(2);
          el.documentCurRowAmountAfterDiscount = (+el.documentCurRowAmountBeforeDiscount + +el.documentCurRowDiscountAmount).toFixed(2);
        }
        this.orderRows.push(el);
      })

      this.toggle_import = 'grid';
      this.saveRows(this.orderRows);
    },

    validate() {
      this.$refs.form.validate()
      this.moveNext();
      this.$refs.form.reset()
    },
    getCustomerName: function (customerId) {
      return (this.customers.find(x => x.id === customerId) || {}).businessName || "";
    },
    getAgentName: function (agentId) {
      return (this.agents.find(x => x.id === agentId) || {}).name || "";
    },
    getListName: function (listId) {
      return (this.priceCatalogDropdown.find(x => x.value === listId) || {}).text || "";
    },
    getPaymentType: function (priceType) {
      return (this.paymentTerms.find(x => x.id === priceType) || {}).description || "";
    },
    shipmentTypeChanged: function (param) {
      this.orderDraft.shipmentType = param;
      switch (param) {
        case 'BOX':
          this.orderDraft.palletHeight = 0;
          this.showPalletHeight = false;
          break;
        case 'PALLET':
          this.orderDraft.palletHeight = 0;
          this.showPalletHeight = true;
          break;
      }
    },
    moveNext: function () {
      if (this.currentStep === 5) {
        if (this.orderDraft.shipmentType === 'PALLET' && this.orderDraft.palletHeight < 1) {
          return this.showError(this.$t('invalidParam', {param:this.$t('palletHeight')}));
        } else {
          this.saveAndReleaseOrderDraft();
          return this.$router.push("/orders");
        }
      }
      if (this.currentStep < 5) this.move(this.currentStep + 1);
    },
    movePrevious: function () {
      this.move(this.currentStep - 1);
    },
    move: async function (step) {
      this.customerChanged(true);
      if (step > 0) {
        this.currentStep = +step;
        this.saveOrderDraft();
      }
      if (this.currentStep === 2) {
        this.orderDraft.orderDate = !!this.orderDraft.orderDate ? this.orderDraft.orderDate : this.defaultOrderDate;
        this.getCustomersForNewOrder({
          agentId: this.orderDraft.agentId ?? 1,
          familyId: this.orderDraft.familyId,
        }).then(() => {
          // this.customerChanged(true);
          if (!this.orderDraft.orderTypeId) {
            this.orderDraft.orderTypeId = this.orderTypesDropdown.find(x => x.text === 'Acquisto' || x.text === 'Purchase')?.value;
          }
        });
        this.invoicingCustomerChanged();
      }
      if (this.currentStep === 3) {
        this.getItemPriceListOfCatalog(this.orderDraft.priceCatalogId);
        this.orderDraftRowsAreLoaded = false;
        this.orderDraftRowsGet().then(() => {
          this.orderDraftRowsAreLoaded = true;
        });
      }
      if (this.currentStep === 4) {
        this.initPaymentTermCollection();
        this.apiCustomersIdIbansGet(this.orderDraft.customerId)
          .then((res) => {
            this.customerIbanDropdown = res.map(item => ({
              text: item.fullAddress,
              value: item.id
            }));
          });
        this.removeUnavailableItems(this.orderRows)
          .then((response) => {
            // this.orderRows.length = 0;
            // this.orderRows.push(...response.collection);
            // this.saveOrderDraftRows(this.orderRows)
            // .then(() => {
            // this.orderDraft.privateNotes = this.orderDraft.privateNotes || "";
            // this.orderDraft.privateNotes += `${!!response.message? response.message : ''} `;
            // this.saveOrderDraft()
            // });
          });
      }
      if (this.currentStep === 5) {
        this.shippingCustomerChanged();
      }
    },

    toggle: function (param) {
      if (param == 'grid') this.dialog = !this.dialog;
      this.toggle_import = param;
    },

    handleNewCollection: function (data) {
      if (!data.length || !data[0].code) return;

      this.orderRows.length = 0;
      data.forEach((el, i) => {

        el.itemCode = el.code.toUpperCase();

        const item = (this.items.find(item => item.code == el.itemCode) || {});

        if (!!item && !!item.id) {

          const lastRowNumber = (this.orderRows[this.orderRows.length - 1] || {}).rowNumber || 0;
          const nextRowNumber = ((lastRowNumber + 10) / 10).toFixed(0) * 10;

          el.itemDescription = item.description || "";
          el.documentCurRowOriginalPrice = (this.itemPrices.find(itemPrice => itemPrice.itemId === item.id) || {}).price || 0.01;
          el.rowDiscountPercentage = el.discount ?? 0;
          el.rowNumber = nextRowNumber;

          if (!!el.itemCode && !!el.quantity && !!el.documentCurRowOriginalPrice) {
            el.documentCurRowAmountBeforeDiscount = (+el.quantity * +el.documentCurRowOriginalPrice).toFixed(2);
            el.documentCurRowDiscountAmount = +el.rowDiscountPercentage === 0 ? 0 : (+el.documentCurRowAmountBeforeDiscount * (el.rowDiscountPercentage / 100)).toFixed(2);
            el.documentCurRowAmountAfterDiscount = (+el.documentCurRowAmountBeforeDiscount + +el.documentCurRowDiscountAmount).toFixed(2);
          }
          this.orderRows.push(el);
        }
      });

      this.saveRows(this.orderRows);
      this.toggle_import = 'grid';

    },

    getCustomer: function () {
      return this.selectedCustomer || {};
    },
    minOrderReached: function () {
      return (!!this.selectedCustomer && !(this.selectedCustomer?.minOrder))
        || this.getFooter().rowsAmountsBeforeDiscount >= (this.selectedCustomer || {}).minOrder;
    },

    closeModalOrder: function () {
      //   this.$emit('closeModalOrder');
      this.modalService.showNewOrderModal = false;
    },
    saveRows: function (p) {
      this.handleUnevailableItems(p)
        .then(() => {
          this.saveOrderDraftRows(p);
        });
    },
    getFooter: function () {
      let result = this.orderRows.reduce(
        (a, b) => {
          a = a || {};
          a.totalQuantity += !!b.quantity ? +b.quantity : 0;
          a.rowsAmountsBeforeDiscount += !!b.documentCurRowAmountBeforeDiscount ? +b.documentCurRowAmountBeforeDiscount : 0;
          a.rowsDiscountAmount += !!b.documentCurRowDiscountAmount ? +b.documentCurRowDiscountAmount : 0;
          a.documentCurRowsTotal += !!b.documentCurRowAmountAfterDiscount ? +b.documentCurRowAmountAfterDiscount : 0;
          return a;
        },
        { totalQuantity: 0, documentCurRowsTotal: 0.0, rowsAmountsBeforeDiscount: 0.0, rowsDiscountAmount: 0.0 }
      );
      this.orderDraft.rowsAmountsBeforeDiscount = result.rowsAmountsBeforeDiscount = (+result.rowsAmountsBeforeDiscount).toFixed(
        2
      );
      this.orderDraft.rowsDiscountAmount = result.rowsDiscountAmount = (+result.rowsDiscountAmount).toFixed(
        2
      );
      this.orderDraft.documentCurRowsTotal = result.documentCurRowsTotal = (+result.documentCurRowsTotal).toFixed(
        2
      );
      return result;
    },
    selectedPaymentTerm: function () {
      this.calculateDeadlines(this.orderDraft);
      const selectedPaymentTerm = this.paymentTerms.find(x => x.id === this.orderDraft.paymentTermId);
      this.orderDraft.documentCurCollectionCharges = selectedPaymentTerm.collectionCharges;
      this.saveOrderDraft();
    },
    selectedPaymentMethod: function () {
      const selectedPaymentMethod = this.paymentMethods.find(x => x.id === this.orderDraft.paymentMethodId);
    },
    orderDatePickerHandler: function (newData) {
      this.orderDraft.orderDate = this.$moment(newData.value).utc();
      this.orderDraft.estimatedDeliveryDate = this.$moment(this.orderDraft.orderDate).add(1, 'days').toDate();
    },
    estimatedDeliveryDatePickerHandler: function (date) {
      this.orderDraft.estimatedDeliveryDate = new Date(date.value);
      this.calculateDeadlines(this.orderDraft);
    },
    applyDiscount: function () {
      if (this.discountType === 'percentage') {
        this.orderDraft.documentCurOrderDiscountAbsolute = 0;
      } else if (this.discountType === 'currency') {
        this.orderDraft.orderDiscountPercentage = 0;
      }

      this.saveOrderDraft();
    },
    getCustomerLocations: function () {
      if (!!this.orderDraft.customerId) {
        this.customerLocationsGet(this.orderDraft.customerId);
      }
    },
    agentChanged: function () {
      if (!this.orderDraft.familyId) {
        this.orderDraft.familyId = this.familiesDropdown.find(x => x.text === 'MYFAMILY [1]')?.value;
      }
    },
    customerChanged: function (soft = false) {
      this.orderDraft.orderDate = !!this.orderDraft.orderDate ? this.orderDraft.orderDate : this.$moment().utc();
      if (!this.orderDraft.estimatedDeliveryDate) {
        this.orderDraft.estimatedDeliveryDate = this.$moment(this.orderDraft.orderDate).add(1, 'days').toDate();
      }
      if (!!this.orderDraft.customerId) {
        this.apiCustomersIdPriceCatalogFamilyIdGet({
          familyId: this.orderDraft.familyId,
          customerId: this.orderDraft.customerId
        }).then((result) => {
          this.orderDraft.priceCatalogId = result.find(x => !!x.default)?.priceCatalogId || this.priceCatalogDropdown[0]?.value || null;
          this.priceCatalogGet()
            .then(res => {
              let currencyId = res.find(el => el.id === this.orderDraft.priceCatalogId).currencyId;
              this.initCurrenciesCollection()
                .then(response => {
                  this.selectedCurrency = response.find(el => el.id === currencyId);
                })
            });
        });
        this.selectedCustomer = this.customers.find(el => el.id == this.orderDraft.customerId) || {};
        this.orderDraft.oldVatPercentageId = this.selectedCustomer.defaultVatRate || null;
        this.apiCustomersIdLocationsGet(this.selectedCustomer.id)
          .then((res) => {
            this.customerLocationsDropdown = res.map(item => ({
              text: `${item.name? `${item.name} - ` : ``}${item.address}, ${item.city}`,
              value: item.id
            }));
            this.orderDraft.customerLocationId = (res.find(x => !!x.default) || {}).id || null;
          });
        this.orderDraft.oldVatPercentageId = this.selectedCustomer.defaultVatRate || null;
        this.orderDraft.paymentTermId = this.orderDraft.paymentTermId ?? this.selectedCustomer.defaultPaymentTermId ?? null;
        this.orderDraft.paymentMethodId = this.selectedCustomer.defaultPaymentMethodId || null;
        if (soft === true) {
          this.orderDraft.invoicingCustomerId = this.orderDraft.invoicingCustomerId || this.selectedCustomer.defaultInvoicingCustomerId;
          this.orderDraft.invoicingCustomerLocationId = this.orderDraft.invoicingCustomerLocationId || this.selectedCustomer.defaultInvoicingCustomerLocationId;
          this.orderDraft.shippingCustomerId = this.orderDraft.shippingCustomerId || this.selectedCustomer.defaultShippingCustomerId;
          this.orderDraft.shippingCustomerLocationId = this.orderDraft.shippingCustomerLocationId || this.selectedCustomer.defaultShippingCustomerLocationId;
        } else {
          this.orderDraft.invoicingCustomerId = this.selectedCustomer.defaultInvoicingCustomerId;
          this.orderDraft.invoicingCustomerLocationId = this.selectedCustomer.defaultInvoicingCustomerLocationId;
          this.orderDraft.shippingCustomerId = this.selectedCustomer.defaultShippingCustomerId;
          this.orderDraft.shippingCustomerLocationId = this.selectedCustomer.defaultShippingCustomerLocationId;
        }
        this.orderDraft.hasDifferentInvoicingCustomer = (!!this.orderDraft.invoicingCustomerId && this.orderDraft.invoicingCustomerId !== null)
          && (!!this.orderDraft.invoicingCustomerLocationId && this.orderDraft.invoicingCustomerLocationId !== null);
        this.orderDraft.hasDifferentShippingCustomer = (!!this.orderDraft.shippingCustomerId && this.orderDraft.shippingCustomerId !== null)
          && (!!this.orderDraft.shippingCustomerLocationId && this.orderDraft.shippingCustomerLocationId !== null);
        this.invoicingCustomerChanged();
        this.shippingCustomerChanged();
      }
    },
    invoicingCustomerChanged: function () {
      if (!!this.orderDraft.invoicingCustomerId) {
        this.apiCustomersIdLocationsGet(this.orderDraft.invoicingCustomerId).then((res) => {
          this.invoicingCustomerLocationsDropdown = res.map(el => ({ text: `${el.name} - ${el.address}, ${el.city}`, value: el.id }));
          if (!!this.orderDraft.invoicingCustomerId && !this.orderDraft.invoicingCustomerLocationId) {
            this.orderDraft.invoicingCustomerLocationId = (res.find(x => !!x.default) || {}).id || null;
          }
        })
      }
    },
    shippingCustomerChanged: function () {
      if (!!this.orderDraft.shippingCustomerId) {
        this.apiCustomersIdLocationsGet(this.orderDraft.shippingCustomerId).then((res) => {
          this.shippingCustomerLocationsDropdown = res.map(el => ({ text: `${el.name} - ${el.address}`, value: el.id }));

          this.orderDraft.hasDifferentShippingCustomer = true;
          
          if (!!this.orderDraft.shippingCustomerId && !this.orderDraft.shippingCustomerLocationId) {
            this.orderDraft.shippingCustomerLocationId = (res.find(x => !!x.default) || {}).id || null;
          }
        })
      }
    },
    changedHasDifferentInvoicingCustomer: function () {
      if (!this.orderDraft.hasDifferentInvoicingCustomer) {
        this.orderDraft.invoicingCustomerId = null;
        this.orderDraft.invoicingCustomerLocationId = null;
      }
      this.orderDraft.hasDifferentInvoicingCustomer = !this.orderDraft.hasDifferentInvoicingCustomer;
    },
    changedShipmentSelectionType: function (value) {
      const draftPhase = JSON.parse(this.orderDraft.draftPhase) || {};
      draftPhase.shipmentSelectionType = this.shipmentSelectionType;
      this.orderDraft.draftPhase = JSON.stringify(draftPhase);
      this.orderDraft.shippingCustomerId = null;
      this.orderDraft.shippingCustomerLocationId = null;
      if (value === 'childrens') {
        this.shippingCustomersDropdown = this.customers.filter(x => x.parentCode === this.selectedCustomer.code).map(item => ({
          text: item.code + " - " + item.businessName,
          value: item.id
        }));
      }
      if (value === 'all') {
        this.shippingCustomersDropdown = this.customers.map(item => ({
          text: item.code + " - " + item.businessName,
          value: item.id
        }));
      }
    },
    getFormattedCurrency: function (value) {
      return `${this.selectedCurrency?.symbol || '€'} ${this.$numerals(value, `0,0.00`)}`;
    },
    changedTaxEligible: function () {
      if (!this.taxEligible) {
        this.orderDraft.oldVatPercentageId = this.vatPercentagesDropdown?.find(x => x.text.startsWith("NOTAX -"))?.value;
        this.saveOrderDraft();
      } else {
        this.apiOrderDraftIdApplyTaxesPost(this.orderDraft.id).catch(() => {
          this.taxEligible = false;
        });
      }
    },
    getCustomerIcon: function () {
      if (!this.orderDraft.customerId) return '';
      return this.customers.find(x => x.id == this.orderDraft.customerId)?.active ? '' : 'mdi-skull'
    },
    getCastellettoVATInfo: function () {
      return this.orderRows.reduce((a, b) => {
        if (!a?.find(x => x.vatPercentageId == b.vatPercentageId)) {
          a.push({
            vatPercentageId: b.vatPercentageId,
            qty: 1,
            documentCurRowTaxable: b.documentCurRowTaxable,
            documentCurRowTaxes: b.documentCurRowTaxes,
          })
        } else {
          let temp = a.find(x => x.vatPercentageId == b.vatPercentageId);
          temp.qty += 1;
          temp.documentCurRowTaxable += b.documentCurRowTaxable;
          temp.documentCurRowTaxes += b.documentCurRowTaxes;
        }
        return a;
      }, []);
    },
    ...call("app/*"),
    ...call("items", ["initItemCollection", "handleUnevailableItems", "removeUnavailableItems"]),
    ...call("agents", ["initAgentCollection"]),
    ...call("families", ["initFamilyCollection"]),
    ...call("customers", [
      "initCustomerCollection",
      "apiCustomersIdLocationsGet",
      "apiCustomersIdIbansGet"
    ]),
    ...call("priceCatalogs", [
      "initPriceCatalogCollection",
      "priceCatalogGet",
      "getItemPriceListOfCatalog",
      "apiCustomersIdPriceCatalogFamilyIdGet"
    ]),
    // ...call("customerLocations", ["customerLocationsGet"]),
    ...call("orders", [
      "apiOrderDraftGet",
      "initOrderDraftCollection",
      "getCustomersForNewOrder",
      "initOrderTypesCollection",
      "initPaymentTermCollection",
      "initPaymentMethodCollection",
      "calculateDeadlines",
      "saveOrderDraft",
      "saveOrderDraftRows",
      "orderDraftRowsGet",
      "saveAndReleaseOrderDraft",
      "apiOrderDraftIdApplyTaxesPost"
    ]),
    ...call("currencies", ["initCurrenciesCollection"]),
    ...call("geoname/*"),
    ...call("customerLocations/*")
  },
  computed: {
    items: get("items/items"),
    itemKits: get("itemKits/itemKits"),
    itemPrices: get('priceCatalogs/itemPrices'),
    agents: get("agents/agents"),
    customers: get("customers/allCustomers"),
    modalService: sync("modalService"),
    activeAgentsDropdown: get("agents/dropdownActiveAgents"),
    familiesDropdown: get("families/dropdownFamilies"),
    customersDropdown: get("customers/dropdownAllCustomers"),
    priceCatalogDropdown: get("priceCatalogs/dropdownPriceCatalogsForOrderEdit"),
    orderTypesDropdown: get("orders/dropdownOrderTypes"),
    vatPercentagesDropdown: get("vats/dropdownVatPercentages"),
    itemPriceList: sync("priceCatalogs/itemPriceList"),
    paymentTermsDropdown: get("orders/dropdownPaymentTerms"),
    paymentTerms: get("orders/paymentTerms"),
    paymentMethodsDropdown: get("orders/dropdownPaymentMethods"),
    paymentMethods: get("orders/paymentMethods"),
    orderRows: sync("orders/orderRows"),
    orderDraft: sync("orders/orderDraft"),
    paymentTermDeadlinesTable: get("orders/paymentTermDeadlinesTable"),
    vatPercentageById: get("vats/vatPercentageById"),
    isFamilySelectionDisabled: function () {
      return !this.orderDraft?.agentId;
    },
    showPaymentMethods: get("tenantConfiguration/showPaymentMethods"),
    showIbanSelector: get("tenantConfiguration/showIbanSelector"),
    geographicalSalesTaxes: get("tenantConfiguration/geographicalSalesTaxes"),
    showTotalError: function () {
      return (this.orderDraft.orderTypeId === 6 && (this.orderDraft.documentCurTotal > 0 || (!!(this.orderRows.find(el => (el.documentCurRowAmountBeforeDiscount > 0)))))) || (this.orderDraft.orderTypeId !== 6 && this.orderDraft.documentCurTotal < 0)
    },
    countries: get("geoname/countries"),
    dropdownCountries: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
    selectionRules(){
      return [
        (v) => !!v || this.$t('required', {param:this.$t('selection')})
      ]
    },
    poRules(){
      return [
        (v) => (v || "").length <= 50 || this.$t('lessThan', {param:this.$t('po'), qty: v.length}),
      ]
    },
    packageSize(){
      return [
        (v) => v === "PALLET",
        (v) => v !== "PALLET"
      ]
    },
    noteRules(){
      return [
        (v) => (v || "").length <= 255 || this.$t('lessThan', {param:this.$t('note'), qty: v.length}),
      ]
    },
    collectionRules(){
      return [
        (v) => v === +v || this.$t('collectionExpensesR1'),
        (v) => v >= 0 || this.$t('collectionExpensesR2'),
      ]
    },
    deadlines(){
      return {
        search: "",
        headers: [
          { text: this.$t('line'), align: "start", value: "line" },
          { text: this.$t('expirationDate'), value: "expirationDate" },
          { text: this.$t('amount'), value: "amount" },
        ],
        collection: [
          {
            line: "",
            expirationDate: "",
            amount: "",
          },
        ],
      }
    },
    packageTypes(){
      return [
        { text: this.$t('box'), value: "BOX" },
        { text: this.$t('pallet'), value: "PALLET" }
      ]
    },
  },
  // mounted() {
  //   this._keyListener = function (e) {
  //     if (e.key === 'Tab') {
  //       e.preventDefault();
  //     }
  //   };
  //   document.addEventListener('keydown', this._keyListener.bind(this));
  // },
  // beforeDestroy() {
  //   document.removeEventListener('keydown', this._keyListener);
  // },
};
</script>

<style scoped>
.resume {
  border-radius: 8px;
  border: 1px solid #3e5565;
}

.date-picker {
  padding-top: 1%;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding-bottom: 0.3%;
  height: 111%;
}

.date-picker:hover {
  border-bottom: 1px solid #333333;
  color: #333333
}

.date-picker:focus {
  border-bottom: 3px solid #333333;
  color: #333333
}


.castelletto {
  border: 1px solid #ececec;
}

.castelletto-top {
  border-top: 2px solid #ececec;
}

.castelletto-bottom {
  border-bottom: 2px solid #ececec;
}

.castelletto-left {
  border-left: 2px solid #ececec;
}

.castelletto-right {
  border-right: 2px solid #ececec;
}

.mini-label-castelletto {
  text-transform: capitalize;
  font-size: smaller;
  font-weight: 600;
}
</style>
