<template>
<div></div>
  <!--div>
    <v-card flat elevation="7">
      <v-row class="px-6 py-3">
        <v-col cols="12" sm="4" class="mb-0 pb-0">
          <v-autocomplete v-model="model.senderId" :items="dropdownSenders" hide-details="auto" label="MITTENTE" required></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field v-model="model.shipDateStamp" label="DATA di Spedizione" required hide-details="auto" type="date"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="model.customerReference" label="Customer Reference" required hide-details="auto" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field v-model="model.codValue" label="COD Value" required hide-details="auto" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="mt-0 pt-0">
          <span v-if="!model.senderId">Pick a Sender</span>
          <span v-else>{{ getInfo("contact") }} (TEL: {{ getInfo("phone") }}) - {{ getInfo("email") }}</span>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="model.specialInstructions" label="Special Instructions" required hide-details="auto" type="text"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-6 py-3">
        <v-col cols="12" sm="12">
          <v-row v-for="(doc, index) in selectedDocs" :key="index+'doc'+doc.id">
            <v-col cols="12" sm="1">
              <v-autocomplete dense outlined clearable v-model="doc.type" :items="dropdownDocuments" label="Doc. Type" required></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field v-model="doc.code" label="Doc. Code" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field v-model="doc.dimWidth" label="Width (cm)" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field v-model="doc.dimLength" label="Length (cm)" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field v-model="doc.dimHeight" label="Height (cm)" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field v-model="doc.dimWeight" label="Weight (kg)" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field v-model="doc.contentDescription" label="Description" required hide-details="auto" type="text" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-btn block @click="removeIndex(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn block outlined @click="selectedDocs.push({})">
                <v-icon class="mr-1">mdi-plus</v-icon>
                ADD ROW
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col cols="12" sm="12" style="display: flex; flex-direction: column; align-items: end;">
              <h3>{{ selectedDocs.length }} COLLI - PESO TOTALE: {{ getWeight() | formatted }} KG</h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-speed-dial bottom right fixed>
        <template v-slot:activator>
          <v-btn class="mx-2" color="info" elevation="24" fab x-large @click="print('X')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn class="mx-2" color="arancione" elevation="24" fab x-large @click="print('T')">
            <div style="height: 30px; width: 30px; object-fit: contain; object-position: center;">
              <v-img src="../assets/zebra.png"></v-img>
            </div>
          </v-btn>
        </template>
      </v-speed-dial>
    </v-card>
  </div-->

</template>

<script>
import { get, sync, call } from "vuex-pathify";
import {ConsignmentDto} from "@myfamilysrl/tntsync_sdk";

export default {
  props: {
    consignment: {
      type: ConsignmentDto,
      required: true
    }
  },
  name: "UpdateTntConsignment",
  created() {
  }
  /*data: () => ({
    model: {
      senderId: null,
      shipDateStamp: new Date(),
      specialInstructions: ""
    },
    selectedDocs: [],
    headersDocs: [
      { text: 'Doc', align: 'start', sortable: true, value: 'code' },
      { text: 'Document', align: 'start', sortable: true, value: 'fulltext' },
      { text: 'Date', align: 'start', sortable: false, value: 'documentDate' },
      { text: 'Notes', value: 'notes' },
    ],
  }),
  computed: {
    dropdownSenders: get("tnt/dropdownSenders"),
    dropdownDocuments: get("tnt/dropdownDocuments"),
  },
  watch: {},
  created() {
    console.log("...");
    this.initialize();
  },
  methods: {
    initialize() {
      this.model.senderId = null;
      this.selectedDocs.length = 0;
    },
    print(mode) {

    },
    getWeight(){
      return this.selectedDocs.reduce((a, b) => {
        a += +(b.dimWeight ?? 0);
        return a;
      }, 0);
    },
    getInfo(param) {
      let selectedSender = this.senders.find(
          (el) => el.id == this.model.senderId,
      );
      switch (param) {
        case "address":
          return `${selectedSender?.address} - ${selectedSender?.zipCode} ${selectedSender?.town} (${selectedSender?.province})`;
        case "contact":
          return `${selectedSender?.contactName ?? "missing contact name"}`;
        case "phone":
          return `${selectedSender?.phone ?? "missing phone"}`;
        case "email":
          return `${selectedSender?.email ?? "missing email"}`;
        default:
          return "";
      }
    },
  }*/
}
</script>

<style>

</style>

