<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="600" height="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                    Upload UBL
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    <v-btn icon @click="closeModal" class="mr-10">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Upload UBL document
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-file-input label="File input" filled prepend-icon="mdi-upload" @change="readContent"></v-file-input>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Code</th>
                                    <th class="text-left">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="el in ubl.content" :key="el.itemCode">
                                    <td>{{ el.itemCode }}</td>
                                    <td>{{ el.quantity }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="confirm">
                        CONFIRM
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    import {call, get} from "vuex-pathify";
    export default {
        name: "UploadUbl",
        created: function() {
            this.initItemCollection();
        },
        data: () => ({
            dialog: false,
            ubl: {
                content : []
            },
        }),
        methods: {
            readContent : function(data) {
                if (!data) return;

                var reader = new FileReader();
                reader.readAsText(data);
                reader.onload = () => {
                    var parser = new DOMParser();
                    var xmlDoc = parser.parseFromString(reader.result,"text/xml");
                    var lines = xmlDoc.getElementsByTagName("cac:OrderLine");


                }
            },
            confirm: function() {
                this.$emit("loadedUbl", this.ubl.content);
                this.closeModal();
            },
            closeModal: function() {
                this.ubl.content.length = 0;
                this.dialog = false;
            },
            ...call("items", [
                "initItemCollection",
            ])
        },
        computed: {
            items: get("items/items"),
        },
    }
</script>