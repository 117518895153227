<template>
    <spreadsheet ref="sheet" :columns="2" :sheetsbar="false" :change="syncData">
        <spreadsheet-sheet 
            :name="'Orders'"
            :columns="columns"
            :filter-columns="[]"
            :data-source="getData()"                
        ></spreadsheet-sheet>
    </spreadsheet>
</template>

<script>
import { sync, get, call } from "vuex-pathify";
import {
  Spreadsheet,
  SpreadsheetSheet,
} from "@progress/kendo-spreadsheet-vue-wrapper";
// import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
export default {
    name: "TelerikSpreadsheet",
    components: {
        spreadsheet: Spreadsheet,
        "spreadsheet-sheet": SpreadsheetSheet,
        // 'datasource': DataSource
    },
    methods: {
        ...call("orderDrafts/*"),
        ...call("orders/*"),
        ...call("items/*"),

        getData : function () {
            return (this.orderRows || []).map(el => ({
                "code" : el.itemCode || "",
                "quantity" : el.quantity || 0,
            }));
        },

        initialize() {
            this.initItemCollection();
            this.orderDraftRowsGet();
        },

        syncData : function ()  {
            const instance = this;
            let collection = [];
            this.sheet.saveJSON()
            .then((data) => {
                
                let mainSheet = !!data && !!data.sheets && !!data.sheets.length? data.sheets[0] : {};
                
                instance.orderRows.length = 0;

                for(let i = 0; i < mainSheet.rows.length ; i++) {
                    
                    let row = mainSheet.rows[i];

                    let localObj = {
                        "code" : `${(((row.cells || []).find(el => el.index == 0) || {}).value || "").toUpperCase()}`,
                        "quantity" : ((row.cells || []).find(el => el.index == 1) || {}).value || 0,
                    };

                    collection.push(localObj);

                    let item = (instance.items.find(item => item.code == localObj.code) || {});

                    let orderRowObject = {
                        rowNumber : (i+1) * 10,
                        itemCode : `${localObj.code}`,
                        itemDescription : `${item.description || ""}`,
                        quantity : localObj.quantity || 1,
                        documentCurRowOriginalPrice : (instance.itemPrices.find(itemPrice => itemPrice.itemId === item.id) || {}).price,
                        discountPercentage : 0,
                        isGift : false,
                        isLoanForUse : false,
                        notes : "",
                    }

                    if (!!orderRowObject.itemCode 
                        && !!orderRowObject.itemDescription 
                        && !!orderRowObject.quantity 
                        && !!orderRowObject.documentCurRowOriginalPrice) {
                        
                        orderRowObject.row_subtotal = (+orderRowObject.quantity * +orderRowObject.documentCurRowOriginalPrice).toFixed(2);
                        orderRowObject.row_discount = +orderRowObject.discount == 0?
                            0 : (+orderRowObject.row_subtotal * (orderRowObject.discount / 100)).toFixed(2); 
                        orderRowObject.row_total = (+orderRowObject.row_subtotal + +orderRowObject.row_discount).toFixed(2);

                        instance.orderRows.push(orderRowObject);
                    }
                }
                
                this.saveOrderDraftRows();

            });
        },
    },
    computed : {
        items: sync("items/items"),
        orderRows : sync('orders/orderRows'),
        itemPrices: get('priceCatalogs/itemPrices'),
    },
    data: function () {
        return {
            sheet : null,
            columns: [
                { width: 600 }, // { width: 165 },
                { width: 600 }, // { width: 360 },
            ],
        }
    },
    mounted: function () {
        this.sheet = this.$refs.sheet.kendoWidget();
        this.sheet.element.css('height', '40vh;');
        this.sheet.element.css('width', '100%');
        this.sheet.resize();
        
        this._keyListener = function(e) {
            if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                this.syncData();
            }
        };
        document.addEventListener('keydown', this._keyListener.bind(this));
    },
    created: function(){
        this.initialize();
    },
    beforeDestroy() { document.removeEventListener('keydown', this._keyListener); }
}
</script>