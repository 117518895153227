<template>
  <kendo-map :center="[45.0226071, 8.6241261]" :zoom="4" style="width: 100%; height: 500px;">
    <!-- :attribution="'&copy; <a href=\'https://osm.org/copyright\'>OpenStreetMap contributors</a>'" -->
    <kendo-map-layer :type="'tile'"
                     :url-template="'https://#= subdomain #.tile.openstreetmap.org/#= zoom #/#= x #/#= y #.png'"
                     :subdomains="['a', 'b', 'c']"
    >
    </kendo-map-layer>
    <!-- <kendo-map-marker :location="markers.location"  :shape="''" :title="markers.title">
    </kendo-map-marker> -->
    <!-- <kendo-map-marker :location="[28.4237178,-81.4093954]" :shape="''" :title="'MyFamily USA <br />651 Gadson St. Groveland FL 34736 USA'" >
    </kendo-map-marker> -->
    <template v-for="(marker, i) in markers">
      <kendo-map-marker :location="marker.location" :key="i" :shape="''" :title="marker.title"></kendo-map-marker>
    </template>
  </kendo-map>
</template>

<script>
import {Map, MapLayer, MapMarker} from '@progress/kendo-map-vue-wrapper';

export default {
  name: 'TelerikMap',
  data: () => ({
    markers: [
    ]
  }),
  created() {
    let calculatedMarkers = [
      {
        shape: "customMarker",
        location: [45.0226071, 8.6241261],
        title: 'MyFamily <br />Str. per Solero, 1, A, 15048 Valenza AL'
      },
      {
        shape: "customMarker",
        location: [28.4237178, -81.4093954],
        title: 'MyFamily USA <br />651 Gadson St. Groveland FL 34736 USA'
      },
    ];

    for (let i = 1; i <= 2; i++) {
      let coordinates = [];
      const maxLat = +90;
      const minLat = -90;
      const maxLong = +180;
      const minLong = -100;
      coordinates.push(+(Math.random() * (maxLat - minLat + 1) + minLat).toFixed(6));
      coordinates.push(+(Math.random() * (maxLong - minLong + 1) + minLong).toFixed(6));
      calculatedMarkers.push({
        shape: "customerMarker",
        location: coordinates,
        title: `${i} <br />Str. per Solero, 1, A, 15048 Valenza AL`
      });
    }
    this.markers.push(...calculatedMarkers);
  },
  components: {
    'kendo-map': Map,
    'kendo-map-layer': MapLayer,
    'kendo-map-marker': MapMarker
  },
}
</script>

<style>
/* span.k-marker  {
    background-image: url('../assets/pittogramma-positivo.jpg');
    background-size: 30px;
    width: 30px;
    height: 30px;
  }
span.k-marker::before {
    display:none;
} */
</style>