<template>
<div>
    <editor style="height:280px; width:100%;" v-model="model.text" @change="handleResult()" @keyup="handleResult()">
        <editor-tool :name="'pdf'"></editor-tool>
        <editor-tool :name="'viewHtml'"></editor-tool>
        <editor-tool :name="'insertUnorderedList'"></editor-tool>
        <editor-tool :name="'insertOrderedList'"></editor-tool>
        <editor-tool :name="'hashtag'" :tooltip="'selectTag'" :title="'#'" :exec="selectTag"></editor-tool>
        <editor-tool :name="'mail'" :tooltip="'selectReference'" :title="'@'" :exec="selectReference"></editor-tool>
    </editor>
    <v-btn fab absolute top right color="primary"><v-icon>mdi-check</v-icon></v-btn>
</div>
</template>

<script>
import { Editor } from '@progress/kendo-editor-vue-wrapper';
import { EditorTool } from '@progress/kendo-editor-vue-wrapper';

export default {
    name: 'TelerikEditor',
    components: {
       Editor,
       EditorTool
    },
    props: {
        disabled: Boolean,
        value:[String, Number],
        loading: Boolean,
        isEdit: Boolean,
        field:String
    },
    data: () => ({
        model: {
            text: "",
            tagCollection: [],
            referenceCollection: [],
            mailCollection: []
        }
    }),
    computed: {
        internalValue: {
            get: function() {
                return this.model.text;
            },
            set: function(newValue) {
                this.handleResult(newValue);
                this.$emit('emitUpdate', { field: this.field, value : newValue})
            }
        },
    },
    methods: {
        handleResult: function(value = null){
            let newValue = value || this.model.text;
            
            this.model.tagCollection.length = 0;
            this.model.tagCollection.push(this.extractTags(newValue));
            this.model.referenceCollection.length = 0;
            this.model.referenceCollection.push(this.extractReferences(newValue));
            this.model.mailCollection.length = 0;
            this.model.mailCollection.push(this.extractEmails(newValue));
        },
        extractEmails: (text) => text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi),
        extractReferences: (text) => text.match(/( @[a-zA-Z0-9._-]+)/gi),
        extractTags: (text) => text.match(/(#[a-zA-Z0-9._-]+)/gi),
        selectTag: function() {
        },
        selectReference: function() {
        },
    }
}
</script>
<style>
    iframe {
        width: 100%;
    }
</style>
