<template>
  <div ref="divWrapper" style="width:100%">
    <chat style="height: 500px; margin: 0; width: 100%; max-width: unset; max-height: unset;" ref="chat" id="chat" @toolclick="onToolClick" :toolbar-toggleable="true">
      <chat-toolbar-button name="sendimage" icon-class="k-icon k-i-clip" text="Send Image"></chat-toolbar-button>
      <chat-toolbar-button name="tagMail" icon-class="k-icon k-i-user" text="Tag"></chat-toolbar-button>
      <template v-if="showTargetInput">
        <v-autocomplete v-model="target"></v-autocomplete>
      </template>
    </chat>

    <upload ref="upload" :async-save-url="'https://demos.telerik.com/kendo-ui/upload/async/save'"
      :async-remove-url="'https://demos.telerik.com/kendo-ui/upload/async/remove'" :async-auto-upload="true"
      :async-with-credentials="false" :drop-zone="'#chat'" :show-file-list="false"
      :validation-allowed-extensions="['.jpg', '.jpeg', '.png', '.bmp', '.gif']" :validation-min-file-size="500"
      @upload="onSuccess">
    </upload>
  </div>
</template>
<script>
  import {
    Chat,
    ChatToolbarButton
  } from '@progress/kendo-chat-vue-wrapper';
  import {
    Upload
  } from '@progress/kendo-upload-vue-wrapper';


  export default {
    name: 'TelerikChat',
    components: {
      'chat': Chat,
      'chat-toolbar-button': ChatToolbarButton,
      'upload': Upload
    },
    mounted() {
      this.$refs.upload.kendoWidget().wrapper.hide()
      var IMAGE_CARD_TEMPLATE = kendo.template(
        `<div class="k-card k-card-type-rich">
        <div class="k-card-body quoteCard">
        <img class="image-attachment" src="#: image #" style="max-height: 120px;" />
        </div>
        </div>`
      )
      kendo.chat.registerTemplate('image_card', IMAGE_CARD_TEMPLATE)
    },
    data: () => ({
      showTargetInput: false,
      target: "",
    }),
    methods: {
      showTarget() {
        this.showTargetInput = !this.showTargetInput;
      },
      onToolClick(ev) {
        ev.name === 'sendimage' ?
          this.$refs.upload.kendoWidget().element.click() :
          this.showTarget();
      },
      onSuccess(e) {
        var that = this
        e.preventDefault()
        for (var i = 0; i < e.files.length; i++) {
          var file = e.files[i].rawFile
          if (file) {
            var reader = new FileReader()
            reader.onloadend = function () {
              that.$refs.chat.kendoWidget().renderAttachments({
                attachments: [{
                  contentType: 'image_card',
                  content: {
                    image: this.result
                  }
                }],
                attachmentLayout: 'list'
              }, that.$refs.chat.kendoWidget().getUser())
            }
            reader.readAsDataURL(file)
          }
        }
      }
    }
  }
</script>