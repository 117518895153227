<template>
  <div>
    <Grid ref="grid" class="grid"
          :style="{ height : gridHeight+'vh' }"
          :data-items="getData()"
          :edit-field="'inEdit'"
          @rowclick="rowClick"
          @itemchange="itemChange"
          @dataStateChange="dataStateChange"
          :columns="getColumns()"
    >
      <template v-slot:itemCode="{props}">
        <DropdownProductCode
            :ref="'dropdown-'+props.dataItem.itemCode"
            :data-item="props.dataItem"
            :itemFamilyId="familyId"
            :field="props.field"
            @change="(e) => ddChange(e, props.dataItem)">
        </DropdownProductCode>
      </template>
      <template v-slot:vatPercentageId="{props}">
        <DropdownVATRate
            :ref="'drop-'+props.dataItem.vatPercentageId"
            :data-item="props.dataItem"
            :field="props.field"
            @change="(e) => dChange(e, props.dataItem)">
        </DropdownVATRate>
      </template>
      <template v-slot:documentCurRowAmountAfterDiscount="{props}">
        <td style="text-align: right;">
          {{ props.dataItem.documentCurRowAmountAfterDiscount | formatted }}
        </td>
      </template>
      <template v-slot:actions="{props}">
        <CommandCell
            :data-item="props.dataItem"
            :kitCollapsing="collapseKits"
            @remove="deleteRow"
            @showKit="showKitDialog"
        ></CommandCell>
      </template>
      <v-overlay :value="isUpdating" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </Grid>

    <v-dialog v-model="dialog" width="75%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >{{ this.selectedKit.rowNumber + " - " + this.selectedKit.itemCode + " - " + this.selectedKit.itemDescription }}
        </v-toolbar>
        <v-card-text class="modal-main-content mt-6">
          <v-data-table
              :headers="kitRowsDialogColumns"
              :items="rowsSelectedKit"
              class="elevation-1"></v-data-table>
        </v-card-text>
        <v-card-text style="height: 40px; position: relative;">
          <v-fab-transition>
            <v-btn color="primary" absolute top right fab @click="closeKitRowsDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {call, get} from "vuex-pathify";
import {Grid} from '@progress/kendo-vue-grid';
import {orderBy} from '@progress/kendo-data-query';
import DropdownProductCode from './DropdownProductCode.vue';
import DropdownVATRate from './DropdownVATRate.vue';
import CommandCell from './CommandCell';

export default {
  name: 'TelerikGrid',
  components: {
    Grid,
    DropdownProductCode,
    CommandCell,
    DropdownVATRate
  },
  props: {
    gridHeight: {
      type: Number,
      default: 45,
    },
    collection: Array,
    collapseKits: {
      type: Boolean,
      default: false,
    },
    familyId: Number,
    vatPercentageId: {
      type: Number | undefined,
      required: true
    },
  },
  data: function () {
    return ({
      dialog: false,
      gridData: [],
      rawCollection: [],
      isUpdating: true,
      editRowNumber: null,
      lastEditedField: null,
      newRecord: {
        rowNumber: 10,
        orderDraftId: 0,
        itemCode: '',
        itemDescription: '',
        quantity: 1,
        documentCurRowOriginalPrice: null,
        rowDiscountPercentage: null,
        isGift: false,
        isLoanForUse: false,
        notes: ''
      },
      kitRows: [],
      selectedKit: {rowNumber: null, itemCode: null, itemDescription: null},
      rowsSelectedKit: [],
      sort: [{field: 'rowNumber', dir: 'asc'}],
    })
  },
  computed: {
    items: get("items/items"),
    itemPrices: get('priceCatalogs/itemPrices'),
    itemKits: get('itemKits/itemKits'),
    kitRowsDialogColumns(){
      return [
        {text: this.$t('nr'), value: 'rowNumber'},
        {text: this.$t('item'), value: 'itemCode'},
        {text: this.$t('description'), value: 'itemDescription'},
        {text: this.$t('quantity'), value: 'quantity'},
        {text: this.$t('price'), value: 'documentCurRowOriginalPrice'},
        {text: '+/- %', value: 'discount'},
        {text: this.$t('gift'), value: 'isGift'},
        {text: this.$t('lfu'), value: 'isLoanForUse'},
        {text: this.$t('notes'), value: 'notes'},
        {text: this.$t('rowTotal'), value: 'documentCurRowAmountAfterDiscount'}
      ]
    }
  },
  watch: {
    collection: function () {
      this.updateGridData(true);
    }
  },
  mounted() {
    this.initialize();
    this._keyListener = function (e) {
      if (e.key === "Backspace" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (!!this.editRowNumber) {
          this.deleteRow({dataItem: { rowNumber: this.editRowNumber }});
        }
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        this.updateGridData();
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        this.updateGridData();
      }
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  methods: {
    initialize() {
      this.rawCollection = this.collection.slice();
      this.updateGridData(true);
    },
    getData() {
      return this.gridData;
    },
    updateGridData: async function (watchedFromParent = false) {
      await this.initItemCollection();
      await this.initItemKitCollection();
      
      if (!!watchedFromParent) {
        this.rawCollection = this.collection.slice();
        this.isUpdating = true;
      }

      this.gridData.length = 0;

      for (const index in this.rawCollection) {
        let gridRow = this.rawCollection[index];
        
        if (!!this.collapseKits) {
          const kit = this.itemKits.find(x => x.code === gridRow.kitCode) || {};
          if (!!gridRow.kitCode) {
            if (gridRow.kitCode === gridRow.itemCode) {
              gridRow['documentCurRowOriginalPrice'] = 0;
              const apiKitRows = await this.apiItemKitIdItemKitRowsGet(kit.id);
              this.kitRows.length = 0;
              this.kitRows = this.kitRows.filter(x => x.kitCode !== gridRow['kitCode']);
              apiKitRows.forEach((el) => {
                let item = {...this.items.find(x => x.id === el.itemId)};
                el['quantity'] = +el['quantity'] || +gridRow['quantity'];
                el['itemCode'] = item.code;
                el['itemDescription'] = item.description;
                el['documentCurRowOriginalPrice'] = (this.itemPrices.find(x => x.itemId === el.itemId) || {}).price || 0.00;
                el['documentCurRowAmountBeforeDiscount'] = (+(el['documentCurRowOriginalPrice'] ?? 0) * +el['quantity']).toFixed(2);
                el['documentCurRowDiscountAmount'] = +el['discount'] === 0 ? 0 : (+(el['documentCurRowAmountBeforeDiscount'] ?? 0) * +(el['discount'] ?? 0)).toFixed(2);
                el['documentCurRowAmountAfterDiscount'] = (+(el['documentCurRowAmountBeforeDiscount'] ?? 0) + +(el['documentCurRowDiscountAmount'] ?? 0)).toFixed(2);
                el['kitCode'] = gridRow['kitCode'];
                this.kitRows.push(el);
              });
              gridRow['documentCurRowOriginalPrice'] = this.kitRows.reduce((a, b) => {
                a = a || 0;
                a += +b['documentCurRowAmountAfterDiscount'] ?? 0;
                return a;
              }, 0).toFixed(2);
              
            }
          }
        } 
        else if (!!gridRow['itemCode'] && !!gridRow['quantity'] && !!gridRow['documentCurRowOriginalPrice']) {
          if (!!gridRow['isGift'] || !!gridRow['isLoanForUse']) {
            gridRow['documentCurRowAmountBeforeDiscount'] = 0.00; 
            gridRow['documentCurRowDiscountAmount'] = 0.00;
            gridRow['documentCurRowAmountAfterDiscount'] = 0.00;
          } else {
            gridRow['documentCurRowAmountBeforeDiscount'] = (+gridRow['quantity'] * +gridRow['documentCurRowOriginalPrice']).toFixed(2);
            gridRow['documentCurRowDiscountAmount'] = +gridRow['rowDiscountPercentage'] === 0 ?
                0 : (+gridRow['documentCurRowAmountBeforeDiscount'] * (gridRow['rowDiscountPercentage'] / 100)).toFixed(2);
            gridRow['documentCurRowAmountAfterDiscount'] = (+gridRow['documentCurRowAmountBeforeDiscount'] + +gridRow['documentCurRowDiscountAmount']).toFixed(2);
          }

        }
        this.gridData.push(gridRow);
      }
      if (!this.gridData || (this.gridData[this.gridData.length - 1] || {}).itemCode !== '') {
        this.addRecord();
      }
      this.gridData = orderBy(this.gridData, this.sort);
      if (!watchedFromParent && !!this.rawCollection.length) {
        this.$emit('updatedCollection', this.rawCollection);
      }
      this.isUpdating = false;
      this.gridData[this.gridData.length-1].inEdit = true;
    },

    // DropdownProductCode event
    ddChange: function (e, dataItem) {
      dataItem.itemCode = e.code;
      let index = this.gridData.findIndex(el => el.rowNumber === dataItem.rowNumber);
      if (index < 0) return; 
      this.gridData[index] = dataItem;
      this.gridData[index]['rowDiscountPercentage'] = this.gridData[index]['rowDiscountPercentage'] || 0;
      this.gridData[index]['itemDescription'] = e.description || this.gridData[index]['itemDescription'] || "";
      this.gridData[index]['vatPercentageId'] = this.vatPercentageId;
      if (e.isKit) {
        this.gridData[index]['kitCode'] = e.code;
      } else {
        this.gridData[index]['kitCode'] = null;
        const itemPrice = (this.itemPrices.find(itemPrice => itemPrice.itemId === e.id) || {}).price;
        this.gridData[index]['documentCurRowOriginalPrice'] = itemPrice !== null && itemPrice !== undefined ? +itemPrice : 0.01;
      }
      
      const data = this.gridData.slice();
      this.rawCollection = data;
    },


    // event
    dChange: function (e, dataItem) {
      dataItem.vatPercentageId = e;
      let index = this.gridData.findIndex(el => el.rowNumber === dataItem.rowNumber);
      if (index < 0) return; 
      this.gridData[index] = dataItem;
      const data = this.gridData.slice();
      this.rawCollection = data;
    },

    // Grid event
    itemChange: function (el) {
      const data = this.gridData.slice();
      const index = data.findIndex(d => d.rowNumber === el.dataItem.rowNumber);

      if (el.field === 'rowDiscountPercentage' && +el.value <= -50) {
        el.value = -50;
      }
      if (el.field === 'rowDiscountPercentage' && +el.value >= 50) {
        el.value = 50;
      }

      if(el.field === 'rowDiscountPercentage') {
        data[index]["discountAmount"] = 0;  
        data[index]["accountingCurRowDiscountAmount"] = 0;  
        data[index]["documentCurRowDiscountAmount"] = 0;         
      }

      
      data[index]["quantity"] = data[index]["quantity"] || 1;
      data[index][el.field] = el.value;

      this.rawCollection = data;

      this.updateGridData();
    },

    showKitDialog: function (e) {
      this.rowsSelectedKit = this.kitRows.filter(x => x.kitCode === e.dataItem.kitCode);
      this.selectedKit = e.dataItem;
      this.dialog = true;
    }
    ,

    deleteRow: function (param = null) {
      if (!!param && !!param.dataItem && !!param.dataItem.rowNumber) {
        this.rawCollection = this.rawCollection.filter(el => el.rowNumber !== this.editRowNumber);
        this.gridData = this.gridData.filter(el => el.rowNumber !== this.editRowNumber);
      }

      this.rawCollection = this.rawCollection.map((el, i) => {
        if (!!el) el.rowNumber = (i + 1) * 10;
        return el;
      });

      this.updateGridData();
    },

    rowClick: function (e) {
      this.closeEdit();
      this.editRowNumber = e.dataItem.rowNumber;
      e.dataItem.inEdit = true;
    },

    closeEdit() {
      this.gridData = this.gridData.map((el, i) => {
        el.inEdit = (i === this.gridData.length - 1);
        return el;
      });
      this.editRowNumber = (this.gridData[this.gridData.length - 1]).rowNumber;
    },

    addRecord() {
      const data = this.gridData.slice();
      const lastRowNumber = (data[data.length - 1] || {}).rowNumber || 0;
      const nextRowNumber = ((lastRowNumber + 10) / 10).toFixed(0) * 10;
      data.push({...this.newRecord, vatPercentageId: this.vatPercentageId });
      data[data.length - 1].rowNumber = nextRowNumber;
      this.gridData = data;
    },
    ...call("items/*"),
    ...call("orders/*"),
    ...call("itemKits/*"),

    dataStateChange(dataState) {
      this.updateGridData();
    },

    closeKitRowsDialog() {
      this.dialog = false;
    },
    getColumns() {
      if (this.$store.get("auth/isInRole", ['SupervisorAgent', 'CommercialSpecialist'])) {
        return [
          {field: "rowNumber", editable: false, title: this.$t('nr'), width: '80px'},
          {field: "itemCode", editable: true, title: this.$t('item'), width: '200px', cell: 'itemCode'},
          {field: "itemDescription", editable: false, title: this.$t('description')},
          {field: "quantity", editable: true, title: this.$t('quantity'), width: '100px'},
          {field: "documentCurRowOriginalPrice", editable: true, title: this.$t('price'), width: '120px'},
          {field: "rowDiscountPercentage", editable: true, title: "+/- %", width: '100px'},
          {field: "vatPercentageId", editable: true, title: this.$t('vatPerc'), width: '120px', cell: 'vatPercentageId'},
          {field: "isGift", editable: true, title: this.$t('gift'), editor: 'boolean', width: '60px'}, // , cell: this.cellFunction
          {field: "isLoanForUse", editable: true, title: this.$t('lfu'), editor: 'boolean', width: '60px'},
          {field: "notes", editable: true, title: this.$t('notes')},
          {field: "documentCurRowAmountAfterDiscount", editable: false, title: this.$t('rowTotal'), width: '80px', cell: "documentCurRowAmountAfterDiscount"},
          {cell: "actions", title: this.$t('actions'), width: '80px'},
        ];
      } else {
        return [
          {field: "rowNumber", editable: false, title: this.$t('nr'), width: '80px'},
          {field: "itemCode", editable: true, title: this.$t('item'), width: '200px', cell: 'itemCode'},
          {field: "itemDescription", editable: false, title: this.$t('description')},
          {field: "quantity", editable: true, title: this.$t('quantity'), width: '100px'},
          {field: "documentCurRowOriginalPrice", editable: false, title: this.$t('price'), width: '120px'},
          {field: "rowDiscountPercentage", editable: true, title: "+/- %", width: '100px'},
          {field: "vatPercentageId", editable: true, title: this.$t('vatPerc'), width: '120px', cell: 'vatPercentageId'},
          {field: "isGift", editable: true, title: this.$t('gift'), editor: 'boolean', width: '60px'}, // , cell: this.cellFunction
          {field: "notes", editable: true, title: this.$t('notes')},
          {field: "documentCurRowAmountAfterDiscount", editable: false, title: this.$t('rowTotal'), width: '80px'},
          {cell: "actions", title: this.$t('actions'), width: '80px'},
        ];
      }
    }
  }
  ,
  beforeDestroy() {
    // this.$emit('updatedCollection', this.gridData);
    document.removeEventListener('keydown', this._keyListener);
  },
};

</script>

