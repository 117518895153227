<template>
  <div>
    <v-tooltip bottom v-if="!!alt">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="!!booleanValue" color="success" v-bind="attrs" v-on="on">mdi-check</v-icon>
        <v-icon v-else color="error" v-bind="attrs" v-on="on">mdi-close</v-icon>
      </template>
      <span>{{ alt }}</span>
    </v-tooltip>
    <div v-else>
      <v-icon v-if="!!booleanValue" color="success">mdi-check</v-icon>
      <v-icon v-else color="error">mdi-close</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "BooleanIcon",
  props: {
    "booleanValue": {
      type: null,
      default: false
    },
    "alt": {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>