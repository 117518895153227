<template>
  <v-card color="red lighten-2" dark>
    <v-card-text>
      <v-autocomplete v-model="model" :items="items" :loading="isLoading" :search-input.sync="search" color="white" hide-no-data hide-selected item-text="name" item-value="API" label="Public APIs" placeholder="Start typing to Search" prepend-icon="mdi-database-search" return-object
      ></v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
    <v-expand-transition>
      <v-list v-if="model" class="red lighten-3">
        <v-list-item v-for="(field, i) in fields" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="field.value"></v-list-item-title>
            <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>

    export default {
        name : "AutocompleteGeoNames",
        data: () => ({
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            search: null,
        }),

        computed: {
            fields () {
                if (!this.model) return []
                return Object.keys(this.model).map(key => ({ key, value: this.model[key] || 'n/a', }))
            },
            items () {
                return this.entries.map(entry => {
                const name = entry.name.length > this.descriptionLimit
                    ? entry.name.slice(0, this.descriptionLimit) + '...'
                    : entry.name
                return Object.assign({}, entry, { name })
                })
            },
        },

        watch: {
            search (val) {

                const instance = this;

                // Items have already been loaded
                if (instance.items.length > 0) return;

                // Items have already been requested
                if (instance.isLoading) return;

                if (!val || !val.length || val.length < 5) return;

                instance.isLoading = true

                // Lazily load input items
                geocoder.get('search',{
                    q: val
                })
                .then(function(response){
                    // instance.count = response.geonames.length;
                    instance.entries = response.geonames
                })
                .catch(function(error){
                })
                .finally(() => (instance.isLoading = false));
            },
        },
    }
</script>