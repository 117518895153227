<template>
    <div>
        <v-dialog transition="dialog-bottom-transition" v-model="dialogCSV" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" v-t="'loadCsv'"></v-card-title>
                <v-card-text class="mt-3">
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-select v-model="csv.separator" :items="separators" :label="$t('separator')" outlined></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-file-input outlined v-model="csv.file" :label="$t('fileInput')" filled @change="readContent"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="confirm" v-t="'close'"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog transition="dialog-bottom-transition" v-model="dialogExport" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" v-t="'exportToGrid'"></v-card-title>
                <v-card-text class="mt-3">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-select v-model="columnExport.code" :items="spreadsheetColumns" :label="$t('code')" outlined></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select v-model="columnExport.quantity" :items="spreadsheetColumns" :label="$t('quantity')" outlined></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select v-model="columnExport.discount" :items="spreadsheetColumns" :label="$t('discount')" outlined></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="mt-3" v-if="!!getPreview().length">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-alert border="bottom" colored-border type="success" elevation="2">{{ `${getPreview().length} ${ $t('validItems') }. ${counterQuantity} ${ $t('itemsQty') }` }}</v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="errors.length">
                        <v-col cols="12" md="12"><v-alert border="bottom" colored-border type="warning" elevation="2" v-if="getErrors('not found')">
                            <p>{{ `${getErrors('not found').counter} ${ $t('invalidParam', {param:$t('itemCode')}) }.` }}</p>
                            <p>{{ `${((getErrors('not found') || {}).collection || []).join(', ')}` }}</p>
                        </v-alert></v-col>
                        <v-col cols="12" md="12"><v-alert border="bottom" colored-border type="warning" elevation="2" v-if="getErrors('not active')">
                            <p>{{ `${getErrors('not active').counter} ${ $t('inactiveItems') }.` }}</p>
                            <p>{{ `${((getErrors('not active') || {}).collection || []).join(', ')}` }}</p>
                        </v-alert></v-col>
                        <v-col cols="12" md="12"><v-alert border="bottom" colored-border type="warning" elevation="2" v-if="getErrors('obsolete')">
                            <p>{{ `${getErrors('obsolete').counter} ${ $t('obsoleteitems') }.` }}</p>
                            <p>{{ `${((getErrors('obsolete') || {}).collection || []).join(', ')}` }}</p>
                        </v-alert></v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="setPreview">{{  !!getPreview().length? $t('reload') : $t('preview') }}</v-btn>
                    <v-btn v-if="!!getPreview().length" color="primary" text @click="exportData" v-t="'export'"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <spreadsheet ref="sheet" :sheetsbar="true" :toolbar="getToolbar()" :change="setPreview">
            <spreadsheet-sheet :name="'Orders'" :columns="columns" :filter-columns="[]" :data-source="localDataSource"></spreadsheet-sheet>
        </spreadsheet>
    </div>
</template>

<script>
    import {call, get, sync} from "vuex-pathify";
    import { Spreadsheet, SpreadsheetSheet } from "@progress/kendo-spreadsheet-vue-wrapper";
    export default {
        name: "TelerikSpreadSheetV2",
        components: {
            spreadsheet: Spreadsheet,
            "spreadsheet-sheet": SpreadsheetSheet,
        },
        methods: {
            getToolbar: function() {
                const instance = this;
                return {
                    home: [
                        "open",
                        { type: "button", text: this.$t('loadCsv'), showText: "both", icon: "k-icon k-i-clip", click: instance.openModal },
                        { type: "button", text: this.$t('saveOrderRows'), showText: "both", icon: "k-icon k-i-export", click: instance.exportModal},
                    ],
                    insert: false,
                    data: false
                }
            },
            openModal: function () {
                this.dialogCSV = true;
            },
            exportModal: function() {
                this.dialogExport = true;
            },
            exportData: function () {
                const instance = this;
                let collection = [];
                this.sheet.saveJSON()
                .then((data) => {
                    let mainSheet = !!data && !!data.sheets && !!data.sheets.length? data.sheets[0] : {};
                    instance.localDataSource.length = 0;
                    for(let i = 0; i < mainSheet.rows.length ; i++) {
                        let row = mainSheet.rows[i];
                        let mycode = `${`${((row.cells || []).find(el => el.index == instance.columnExport.code) || {}).value}`.toUpperCase().trim().replaceAll("'", '')}`;
                        let item = instance.items.find(el => el.code == mycode || el.barcode == mycode);
                        let localObj = {
                            "code" : item?.code || mycode,
                            "quantity" : (""+(((row.cells || []).find(el => el.index == instance.columnExport.quantity) || {}).value || 0)).match(/\d+/)?.flat()[0],
                            "discount" : (""+(((row.cells || []).find(el => el.index == instance.columnExport.discount) || {}).value || 0)).match(/\-?\d+([.,]\d+)/)?.flat()[0],
                        };
                        !!(+localObj.quantity) && instance.localDataSource.push(localObj);
                    }
                    instance.$emit("loadedDataOnSpreadSheet", instance.localDataSource)
                });
            }, 
            readContent : function(data) {
                if (!data) return;
                var reader = new FileReader();
                reader.readAsText(data);
                reader.onload = () => {
                    let collection = [];
                    reader.result.split(/\r?\n|\r/).filter(el => !!el).forEach((el) => {
                        let row = [];
                        const cells = el.split(this.csv.separator);
                        cells.forEach((x) => {
                            row.push({
                                value: x,
                                fontSize: 12,
                                textAlign: "center"
                            })
                        });
                        collection.push({
                            height: 18,
                            cells: row
                        })
                    });
                    const jsonElem = {
                        "sheets": [{
                            "name": "Orders",
                            "rows": collection
                        }]
                    };
                    this.sheet.fromJSON(jsonElem);
                    this.confirm();
                }
            },
            confirm: function() {
                this.csv.file = null;
                this.closeModal();
            },
            closeModal: function() {
                this.csv.content.length = 0;
                this.dialogCSV = false;
            },
            setPreview: function() {
                const instance = this;
                instance.preview.length = 0;
                instance.counterQuantity = 0;
                instance.errors.length = 0;
                this.sheet.saveJSON()
                .then((data) => {
                    let mainSheet = !!data && !!data.sheets && !!data.sheets.length? data.sheets[0] : {};
                    for(let i = 0; i < mainSheet.rows.length; i++) {
                        // (mainSheet.rows.length < 5? mainSheet.rows.length : 5)
                        let row = mainSheet.rows[i];
                        let code = `${`${((row.cells || []).find(el => el.index == instance.columnExport.code) || {}).value}`.toUpperCase().trim().replaceAll("'", '')}`;
                        let item = instance.items.find(el => el.code == code || el.barcode == code);
                        if(!item) instance.errors.push({ code : code, reason: 'not found'});
                        else if(!item.active) instance.errors.push({ code : code, reason: 'not active'});
                        else if(!!item.obsolete) instance.errors.push({ code : code, reason: 'obsolete'});
                        else {
                            let quantity = (""+(((row.cells || []).find(el => el.index == instance.columnExport.quantity) || {}).value || 0)).match(/\d+/)?.flat()[0];
                            let localObj = { "code" : code, "quantity" : quantity };
                            instance.counterQuantity += +quantity;
                            instance.preview.push(localObj);
                        }
                    }
                });
            },
            getErrors: function(param) {
                let response = this.errors.reduce((a, b) => {
                    a = a || {}; 
                    a[b.reason] = a[b.reason] || { collection : [], counter : 0 };
                    a[b.reason].collection.push(b.code);
                    a[b.reason].counter += 1;
                    return a;
                }, {});
                return response[param];
            },
            getPreview: function() {
                return this.preview;
            },
            
            ...call("items/*"),
        },
        data: () => ({
            sheet : null,
            loader : null,
            loading : false,
            dialogCSV: false,
            dialogExport: false,
            preview: [],
            errors: [],
            counterQuantity: 0,
            csv : {
                separator: ';', 
                content : [] 
            },
            columnExport: {
                code : 0,
                quantity : 1
            },
            spreadsheetColumns: [{ value: 0, text: 'A' },{ value: 1, text: 'B' },{ value: 2, text: 'C' },{ value: 3, text: 'D' },{ value: 4, text: 'E' },{ value: 5, text: 'F' },{ value: 6, text: 'G' },{ value: 7, text: 'H' },{ value: 8, text: 'I' },{ value: 9, text: 'J' },{ value: 10, text: 'K' },{ value: 11, text: 'L' },{ value: 12, text: 'M' },{ value: 13, text: 'N' },{ value: 14, text: 'O' },{ value: 15, text: 'P' },{ value: 16, text: 'Q' },{ value: 17, text: 'R' },{ value: 18, text: 'S' },{ value: 19, text: 'T' },{ value: 20, text: 'U' },{ value: 21, text: 'V' },{ value: 22, text: 'W' },{ value: 23, text: 'X' },{ value: 24, text: 'Y' },{ value: 25, text: 'Z' },],
            columns: [/* { width: 600 }, { width: 600 }, */],
            localDataSource : [
                { code : "", quantity : "" }
            ],
        }),
        
        created() {
            this.initItemCollection()
        },
        mounted: function () {
            this.sheet = this.$refs.sheet.kendoWidget();
            this.sheet.element.css('height', '40vh;');
            this.sheet.element.css('width', '100%');
            this.sheet.resize();

            this._keyListener = function(e) {
                if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    this.exportData();
                }
            };
            
            document.addEventListener('keydown', this._keyListener.bind(this));
        },
        beforeDestroy() { 
            document.removeEventListener('keydown', this._keyListener); 
        },
        computed: {
            items: get("items/items"),
            separators(){
                return [
                    { value : ';', text : this.$t('semicolon') },
                    { value : ',', text : this.$t('comma') },
                    { value : '|', text : this.$t('pipe') },
                    { value : '&', text : this.$t('ampersand') }
                ]
            }
        }
    }
</script>

<style>
    .fake-icon {
        margin: 8px;
        font-size: x-large;
        font-weight: bold;
    }
    .block {
        text-align: center;
    }
</style>
