<template>
<a></a>
</template>

<script>


export default {
  name: "RowOrderTable",
  props: [
    'headers', 'data'
  ],
  components: {
  },
};
</script>

